<template>
    <div
        class="inGameWrap"
        ref="rootWrap">
        
        <div class="inGameHeader">
            <div class="headerMid">
                <span class="Icon"><img
                    v-if="roomInfo?.[gameIdx]?.['logo']"
                    v-bind:src="roomInfo[gameIdx]['logo']"
                    alt=""
                ></span>
                <span class="title">
                    {{ roomInfo[gameIdx]['roomName'] }}
                </span>
            </div>
            <div
                class="headerSide"
                ref="sidePaddingLeft">
                <div class="headerLeft">
                    <span>{{ $t('fightNo') }} {{ getVinusSabongFightNo(sabongInfo["rooms"]) }}</span>
                    <span>{{ $t('gmt-ist') }} {{ moment().format("YYYY-MM-DD") }}  {{ currentTime["time"] }}</span>
                </div>
                <div
                    class="headerRight"
                    ref="sidePaddingRight">
                    <div class="mrnBetWrap">
                        <span
                            class="name"
                            v-bind:style="setBetLocale.color.meron">
                            {{ $t(setBetLocale.text.meron) }}<span class="odds">
                                x{{ Number(betUsers['list']?.['bet_info']?.['meron_odd']) || commision }}
                            </span>
                        </span>
                        <div class="betInfo">
                            <div class="inr">
                                <p
                                    class="num"
                                    v-numberFormat="calcRate(betUsers['list']?.['total_bet']?.['meron_total'], exchangeInfo) || 0"></p>
                            </div>
                            <div class="inGameProgressBarTop">
                                <div
                                    class="aniProgressBarWala"
                                    v-bind:style="{ 'width': `${betUsers['list']?.['bet_info']?.['meron_dist'] || 0}%`}"
                                >
                                </div>
                                <div class="ProgressBarHp">
                                    <span>{{
                                        `${betUsers["list"]?.["bet_info"]?.["meron_dist"] == "NaN" ? "0.00" : betUsers["list"]?.["bet_info"]?.["meron_dist"] || "0.00"}%`
                                    }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="walaBetWrap">
                        <span
                            class="name"
                            v-bind:style="setBetLocale.color.wala">
                            {{ $t(setBetLocale.text.wala) }}<span class="odds">
                                x{{ Number(betUsers['list']?.['bet_info']?.['wala_odd']) || commision }}
                            </span>
                        </span>
                        <div class="betInfo">
                            <div class="inr">
                                <p
                                    class="num"
                                    v-numberFormat="calcRate(betUsers['list']?.['total_bet']?.['wala_total'], exchangeInfo) || 0"></p>
                            </div>
                            <div class="inGameProgressBarBot">
                                <div
                                    class="aniProgressBarMrn"
                                    v-bind:style="{ 'width': `${betUsers['list']?.['bet_info']?.['wala_dist'] || 0}%`}"
                                >
                                </div>
                                <div class="ProgressBarHp">
                                    <span>{{
                                        `${betUsers["list"]?.["bet_info"]?.["wala_dist"] == "NaN" ? "0.00" : betUsers["list"]?.["bet_info"]?.["wala_dist"] || "0.00"}%`
                                    }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="inGameBody">
            <div class="inGameLeft">
                <div class="inGameTopBox">
                    <div class="inGameTopMidBox">
                        <div class="videoWrap">
                            <!--<img src="@/assets/sabong/video-logo.png">-->
                            <!--<video src="../../assets/sabong.mp4" autoplay loop muted/>-->
                            <!--<CameraWebRTC :isSabong="true"/>-->
                            <SabongCam v-bind:vendor="route.params.gameType"/>
                            <!--<div class="chatWrap">
                            <input class="sendMsg" placeholder="Send Chatting Message" type="text">
                            <div class="chattingBox">
                                <div class="sentMsg">
                                    <span class="chatTime">[22:00]</span>
                                    <span>user101</span>
                                    <span>Hi</span>
                                </div>
                            </div>-->

                            <div
                                v-if="currentGameResult"
                                class="matchRsltWrap"
                                v-bind:class="currentGameResult"
                            >
                                <h1>{{ $t(setBetLocale.text[currentGameResult]) }}</h1>
                                <div
                                    class="resultPriceBox"
                                    v-if="currentGameResult != 'cancel'">
                                    <span v-numberFormat="realWinAmount"></span>
                                </div>
                                <div
                                    class="cancelResultWrap"
                                    v-else>
                                    <div>
                                        {{ $t('cancelInfo1') }} ({{ currentOdds }})
                                    </div>
                                    <div>
                                        {{ $t('cancelInfo2') }}
                                    </div>
                                    <div
                                        class="resultBox"
                                        v-bind:class="currentSubRooster">
                                        {{ currentSubRooster }}
                                    </div>
                                </div>
                            </div>
                            <div
                                v-bind:style="toggleSlideMenu"
                                class="inGameMenu"
                            >
                                <div v-on:click="slideMenu">
                                    <img
                                        src="../../assets/sabong/icon/arrow.png"
                                        v-bind:class="{ 'slideMenuBtn': !isShow }"
                                    >
                                </div>
                                <InGameIcons />
                            </div>
                            <div
                                class="btmWrap"
                                v-if="isShowBtnInterface && isBetStart"
                            >
                                <button
                                    v-bind:disabled="isApiCalling"
                                    v-bind:style="{ 'opacity': isApiCalling ? '0.7' : '1' }"
                                    class="sabongCancelBtn"
                                    v-on:click.stop="clickBetCancel()"
                                >
                                    <img src="../../assets/sabong/icon/icon_cancel2.png">
                                    {{ $t('cancel') }}
                                </button>
                                <button
                                    v-bind:disabled="isApiCalling"
                                    v-bind:style="{ 'opacity': isApiCalling ? '0.7' : '1' }"
                                    class="sabongConfirmBtn"
                                    v-on:click.stop="clickBetConfirm()"
                                >
                                    <img src="../../assets/sabong/icon/check.png">
                                    {{ $t('confirm') }}
                                    <span class="betCountdown">
                                        ({{ betCountDown }})
                                    </span>
                                </button>
                            </div>
                            <div
                                class="timeBankWrap"
                                v-if="lastCallTime"
                            >
                                <TimeBank v-bind:lastCallTime="lastCallTime"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="inGameBtmBox">
                    <div class="inGameBtmLeftBox">
                        <div class="userPlayInfo">
                            <div>
                                <span>{{ $t('balance') }}</span>
                                <ul>
                                    <li v-numberFormat="loginUser['balance']"></li>
                                    <li class="currency">
                                        {{ loginUser["currency"] }}
                                    </li>
                                </ul>
                            </div>
                            <div>
                                <span>{{ $t('bet') }}</span>
                                <ul>
                                    <li v-numberFormat="userBets['total']"></li>
                                    <li class="currency">
                                        {{ loginUser["currency"] }}
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="scoreboardBox sbb1">
                            <ul class="betDetailBox">
                                <li
                                    v-for="(img,key) in betDetailIcon1"
                                    v-bind:key="key"
                                >
                                    <div
                                        class="label"
                                        v-bind:class="img['label']"
                                        v-bind:style="img['bgcolor']">
                                        {{ img['label'] }}
                                    </div>
                                    <span>{{ img["tx1"] }}</span>
                                </li>
                            </ul>
                            <div class="scoreboardInner">
                                <BaseRoad v-bind:gameType="gameType"/>
                            </div>
                        </div>
                    </div>
                    <div class="inGameBtmMidBox">
                        <div
                            class="battingBlock"
                            v-if="loginUser.balance == 0">
                            {{ $t('noBalance') }}
                        </div>
                        <div
                            class="messageBox"
                            v-bind:class="getVinusSabongRoomStatus(sabongInfo['rooms'])['tx']">
                            <!--이곳에 게임 메시지가 표시되는 곳입니다 -->
                            <p>{{ getVinusSabongRoomStatus(sabongInfo['rooms'])['tx'] }}</p>
                        </div>
                        <div class="betBoardBox">
                            <div
                                class="betBoard meronBoard"
                            >
                                <p>
                                    {{ $t(setBetLocale.text.meron) }}
                                    <span>x{{ Number(betUsers["list"]?.["bet_info"]?.["meron_odd"]) || commision }}</span>
                                </p>
                                <div class="betBoardBotWrap">
                                    <!--class="autoBet" 자동베팅을 할 것인지 설정하는 토글버튼입니다. input:checkbox로 제작하였으며       checked가 되면 배경이 초록색으로 바뀝니다.-->
                                    <div class="autoBet toggle-switch">
                                        <span>{{$t('auto')}}</span>
                                        <input
                                            type="checkbox"
                                            id="meronAuto"
                                            name="meronAuto"
                                            v-on:click="autoBetMeron = !autoBetMeron">
                                        <label for="meronAuto">
                                            <span class="toggle-track"></span>
                                        </label>
                                    </div>
                                    <div class="betPlusMainus">
                                        <span>{{ $t('bet') }}</span>
                                        <div class="betPlMaBox">
                                            <span><img
                                                src="../../assets/icons/minus.png"
                                                alt="meronBtnPlus"
                                                v-on:click="btnMinus"
                                                v-bind:class="{ 'on': clickedBoardKey === 'meron' && clickMinus}"></span>
                                            <!-- 수기입력가능. -->
                                            <input
                                                type="text"
                                                v-model="userBets['meron']"
                                                readonly>
                                            <span><img
                                                src="../../assets/icons/plus.png"
                                                alt="meronBtnMinus"
                                                v-on:click="btnPlus"
                                                v-bind:class="{ 'on': clickedBoardKey === 'meron' && clickPlus}"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="betBoard walaBoard"
                            >
                                <p>
                                    {{ $t(setBetLocale.text.wala) }}
                                    <span>x{{ Number(betUsers["list"]?.["bet_info"]?.["wala_odd"]) || commision }}</span>
                                </p>
                                <div class="betBoardBotWrap">
                                    <div class="autoBet toggle-switch">
                                        <span>{{ $t("auto") }}</span>
                                        <input
                                            type="checkbox"
                                            id="walaAuto"
                                            name="walaAuto"
                                            v-on:click="autoBetWala = !autoBetWala">
                                        <label for="walaAuto">
                                            <span class="toggle-track"></span>
                                        </label>
                                    </div>
                                    <div class="betPlusMainus">
                                        <span>{{ $t("bet") }}</span>
                                        <div class="betPlMaBox">
                                            <span><img
                                                src="../../assets/icons/minus.png"
                                                alt="walaBtnMinus"
                                                v-on:click="btnMinus"
                                                v-bind:class="{ 'on': clickedBoardKey === 'wala' && clickMinus}"></span>
                                            <input
                                                type="text"
                                                v-model="userBets['wala']"
                                                readonly>
                                            <span><img
                                                src="../../assets/icons/plus.png"
                                                alt="walaBtnPlus"
                                                v-on:click="btnPlus"
                                                v-bind:class="{ 'on': clickedBoardKey === 'wala' && clickPlus}"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="betAddBox">
                            <div class="betAdd meronAdd">
                                <span
                                    v-for="chip in chips['Pro']"
                                    v-bind:key="chip"
                                    v-bind:class="{'active' : activeChip['meron'] == chip['key']}"
                                    v-on:click="clickChip(chip, 'meron')">{{ chip['tx'] }}</span>
                            </div>
                            <div class="betAdd walaAdd">
                                <span
                                    v-for="chip in chips['Pro']"
                                    v-bind:key="chip"
                                    v-bind:class="{'active' : activeChip['wala'] == chip['key']}"
                                    v-on:click="clickChip(chip, 'wala')">{{ chip['tx'] }}</span>
                            </div>
                        </div>

                    </div>
                    <!-- <div class="inGameBtmRightBox">
                        <div class="scoreboardBox sbb2">
                            <ul class="betDetailBox">
                                <li
                                    v-for="(img,key) in betDetailIcon2"
                                    v-bind:key="key"
                                >
                                    <img v-bind:src="img['src']">
                                    <span>{{ img["tx1"] }}</span>
                                </li>
                            </ul>
                            <div class="scoreboardInner">
                                <BigRoad v-bind:gameType="gameType"/>
                            </div>
                        </div>
                        <div class="scoreboardBox sbb3">
                            <ul class="betDetailBox">
                                <li
                                    v-for="(img,key) in betDetailIcon3"
                                    v-bind:key="key"
                                >
                                    <img v-bind:src="img['src']">
                                    <span>{{ img["tx1"] }}</span>
                                </li>
                            </ul>
                            <div class="scoreboardInner">
                                <BigRoadText v-bind:gameType="gameType"/>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
            <div class="inGameRight">
                <div class="userBetList">
                    <div class="userBetListWrap">
                        <div class="listTopTxt">
                            <h2>{{$t('betList')}}</h2>
                            <div>
                                <span>{{ (betUsers['list']?.['meron']?.length ? betUsers['list']?.['meron']?.length : 0) + (betUsers['list']?.['wala']?.length ? betUsers['list']?.['wala'].length : 0) }}</span>
                                <img src="@/assets/icons/lobby_listbox/user-icon2.png">
                            </div>
                        </div>
                        <div class="userBetListInner">
                            <ul class="innerTit">
                                <li class="user">
                                    {{ $t('userInfo') }}
                                </li>
                                <li class="bet">
                                    {{ $t('bet') }}
                                    <span class="currency">
                                        ({{ loginUser.currency }})
                                    </span>
                                </li>
                                <li class="betRooster">
                                    {{ $t('betRooster') }}
                                </li>
                                <li class="result">
                                    {{ $t('result') }}
                                    <span class="currency">
                                        ({{ loginUser.currency }})
                                    </span>
                                </li>
                            </ul>
                            <div class="innerListWrap">
                                <ul

                                    class="list"
                                    v-for="data in totalBetList"
                                    v-bind:key="data"
                                    v-bind:class="{
                                        'win': currentGameResult == data['rooster']
                                    }"
                                >
                                    <li class="user">
                                        <div class="flegImg"><img
                                            v-bind:src="getFlagImage(data?.['currency_code'])"
                                            alt="flag"
                                        ></div>
                                        <span class="userName">
                                            {{ setCollapsed(data?.['user_id']) }}
                                        </span>
                                    </li>
                                    <li class="bet">
                                        <span
                                            class="yellow"
                                            v-numberFormat="calcRate(data?.['amount'], exchangeInfo)">
                                        </span>
                                    </li>
                                    <li class="betRooster">
                                        <span
                                            v-bind:class="data?.['rooster']"
                                            v-bind:style="setBetLocale.bgcolor[data?.['rooster']]">
                                            {{ $t(setBetLocale.text[data?.['rooster']]) }}
                                        </span>
                                    </li>

                                    <li class="result">
                                        <!--                                                                        <span
                                            class="yellow"
                                            v-numberFormat="data['winAmount'] > 0 ? calcRate(data['amount'] + (data['amount'] * Number(betUsers['list']?.['bet_info']?.[`${currentGameResult}_odd`]) || 1.86), exchangeInfo) : 0">
                                        </span>-->
                                        <span
                                            class="yellow"
                                            v-numberFormat="calcRate(data['winAmount'], exchangeInfo, data)">
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
    import { chips, loginUser, topics, sabongInter } from '@/js/reactive';
    import router from '@/router';
    import { useRoute } from "vue-router";
    import { getCookie, setCollapsed } from "@/js/utils";
    import { SABONG_GAME_TYPE_IDX, LOGO } from "@/js/const";
    import {setCurrencyInfo, getFlagImage, setCommisionInfo} from "@/js/common";
    import {
        setCurrencyChip,
        getGameResult,
        sabongInfo,
        generateSabongInfo,
        isShowBtnInterface,
        clearBetData,
        initData2,
        userBets,
        betUsers,
        getBetUsers,
        getSabongEvents,
        bet,
        getVinusSabongRoomStatus,
        getVinusSabongFightNo,
        isApiCalling
    } from "@/js/inGame/sabongInter";
    import { onMounted, onBeforeMount, onUnmounted, reactive, ref , watchEffect, watch, computed } from "vue";
    import moment from "moment-timezone";
    import { getBalance, getPing } from "@/js/request";
    import { generateSabongScoreBoard, sabongRoadMap } from "@/js/scoreBoard/generator";
    import { MQTT } from "@/plugins/mqtt";
    import { calcRate } from '@/js/inGame/exchange';
    import { useStore } from 'vuex';
    
    import BaseRoad        from "@/components/scoreBoard/BaseRoad";
    import BigRoad         from "@/components/scoreBoard/BigRoad";
    import BigRoadText     from "@/components/scoreBoard/BigRoadText";
    import InGameIcons     from "@/components/common/InGameIcons";
    import SabongCam       from "@/components/common/SabongCam";
    import TimeBank        from "@/components/common/TimeBank";
    /*	https://vuejsexamples.com/creating-an-animated-number-counter-in-vue3 */
    import Vue3autocounter from "vue3-autocounter";

    //사봉 로고, 배너 이미지들
    import pccLogo from '@/assets/sabong/logo/PCC2021.Live.png';
    import interLogo from '@/assets/sabong/logo/sabong_logo_INT.png';
    import ucfcLogo from '@/assets/sabong/logo/UCFC.png';
    import wsaLogo from '@/assets/sabong/logo/WSA.png';
    import oneBetLogo from '@/assets/sabong/logo/logo_ONEBET.png';
    import ncaLogo from '@/assets/sabong/logo/logo_NCA.png';
    import KGILogo from '@/assets/sabong/logo/logo_KGI.png';
    import KGIBanner from '@/assets/sabong/banner/banner_KGI.png';
    import vinusLogo from '@/assets/sabong/logo/VINUS_SABONG.png';
    import vinusBanner from '@/assets/sabong/banner/vinus.png';

    const store = useStore();

    const totalBetList = computed(() => {
        return betUsers['totalBetList'];
    });

    const route             = useRoute();
    const sidePaddingLeft = ref(sidePaddingLeft);
    const sidePaddingRight = ref(sidePaddingRight);
    const clickedBoardKey   = ref(undefined);
    const clickPlus = ref(false);
    const clickMinus = ref(false);
    const recentResult      = ref(undefined);
    const rootWrap          = ref(undefined);
    const currentFightNo    = ref(undefined);
    const lastCallTime      = ref(undefined);
    const currentGameResult = ref(undefined);
    const currentSubRooster = ref(undefined);
    const currentOdds = ref (undefined);
    const realWinAmount     = ref(0);
    const isBetStart        = ref(false);
    const gameType          = route.params.gameType == "pcc" ? "vinusPcc" : route.params.gameType;
    const gameIdx           = SABONG_GAME_TYPE_IDX[gameType];
    const currentTime = reactive({
        'date': undefined,
        'time': undefined
    })
    const timer = {};
    const momentTimer          = ref(undefined);
    const prevEventId = ref(undefined);
    const selectChipKey = ref(undefined);
    const selectChip = reactive({
        'meron' : {'chip' : {}},
        'wala' : {'chip' : {}}
    });
    const activeChip = reactive({
        'meron' : undefined,
        'wala' : undefined,
    });

    const setBetLocale = LOGO[location.hostname].betLocale;

    const roomInfo = [
        {
            'roomName': 'PCC2021.Live',
            'logo': pccLogo,
        },
        {
            'roomName': LOGO?.[location.hostname]?.interRoomTitle || LOGO['vinusinter.com'].interRoomTitle,
            'logo': LOGO?.[location.hostname]?.interRoomLogo || LOGO['vinusinter.com'].interRoomLogo,
        },
        {
            'roomName': 'WSA',
            'logo': wsaLogo,
        },
        {
            'roomName': 'One Bet',
            'logo': oneBetLogo,
        },
        {
            'roomName': 'NCA',
            'logo': ncaLogo,
        },
    ];
    let autoBetMeron = ref(false);
    let autoBetWala = ref(false);
    let commision = ref(0);
    // 로그인한 유저의 CMS에 등록된 환율 정보
    const exchangeInfo = ref(undefined);

    watch(isBetStart, (newVal, oldVal) => {
        if (newVal == false && oldVal == true) {
            isBetStart.value    = false;
            selectChipKey.value = "";
        }
    });

    watchEffect(async () => {
        if (sabongInfo["rooms"]?.["event_id"]) {
            prevEventId.value = sabongInfo["rooms"]?.["event_id"];
        }
        if (sabongInfo["rooms"]) {
            let betStatus      = getVinusSabongRoomStatus(sabongInfo["rooms"])["tx"];
            isBetStart.value   = betStatus == "open" || betStatus == "lastCall" ? true : false;
            lastCallTime.value = sabongInfo["rooms"]?.last_call ? sabongInfo["rooms"]?.last_call : undefined;
            if (currentFightNo.value && sabongInfo["rooms"]["fight_no"] != currentFightNo.value) {
                initData2(autoBetMeron.value, autoBetWala.value);
            }
            currentFightNo.value = sabongInfo["rooms"]["fight_no"];
        }
        // 서버 토픽 게임IDx
        if (topics?.[`sabong_result_${gameIdx}`]) {
            currentGameResult.value = topics[`sabong_result_${gameIdx}`]?.["rooster"] || "";
            currentSubRooster.value = topics[`sabong_result_${gameIdx}`]?.['subRooster'] || '';
            // jh
            currentOdds.value = `${setBetLocale.text.meron}: ${topics[`sabong_result_${gameIdx}`]?.['meron_odd']} ${setBetLocale.text.wala}: ${topics[`sabong_result_${gameIdx}`]?.['wala_odd']}`
            if (topics?.[`sabong_result_${gameIdx}`]?.user) {
                let find = topics[`sabong_result_${gameIdx}`]["user"]?.[getCookie("loginId")];
                if (find) {
                    realWinAmount.value = find["win_amount"] > 0 ? find["win_amount"] : -find["bet_amount"];
                }
            }
            await getGameResult(gameType, prevEventId.value);
            let timer = setTimeout(async () => {
                currentGameResult.value = undefined;
                currentSubRooster.value = undefined;
                currentOdds.value = undefined;
                realWinAmount.value     = 0;
                //0326 generateSabongScoreBoard(sabongInfo["history"], gameType);
                clearTimeout(timer);
            }, 5000);
            await getBetUsers();
            delete topics[`sabong_result_${gameIdx}`];
        }
    });

    const clickChip = (chip, type) => {
        if (!isBetStart.value) {
            return;
        }
        active(chip, type);
        selectChipKey.value = type;
        selectChip[selectChipKey.value]['chip'] = {...chip};
    }

    const active = (chip, type) => {
        activeChip[type] = chip['key'];
    }

    const btnMinus = async (e) => {
        if(betCountDownTimer){
            return;
        }
        clickMinus.value = true;
        clickedBoardKey.value = e.target.alt.indexOf('meron') > -1 ? 'meron' : 'wala';
        selectChip[clickedBoardKey.value]['chip']['value'] = -Math.abs(selectChip[clickedBoardKey.value]['chip']['value']);
        await bet(selectChip[clickedBoardKey.value], clickedBoardKey.value);

        setTimeout(() => {
            clickedBoardKey.value = undefined;
            clickMinus.value = false;
        }, 250);
    }

    const betCountDown = ref(5);
    let betCountDownTimer = undefined;
    const clearBetCountDown = () => {
        clearInterval(betCountDownTimer);
        betCountDownTimer = undefined;
        betCountDown.value = 5;
    }
    const clickBetConfirm = async () => {
        if(userBets['meron'] + userBets['wala'] + userBets['draw'] <= 0|| betCountDownTimer){
            return;
        }
        betCountDown.value -=1;
        betCountDownTimer = setInterval(async () => {
            if(betCountDown.value == 1){
                await bet('','',true, autoBetMeron.value,autoBetWala.value);
                clearBetCountDown();
                return;
            }
            betCountDown.value -=1;
        }, 1000);
    }

    const clickBetCancel = () => {
        clearBetCountDown();
        clearBetData();
    }

    const btnPlus = async (e) => {
        if(betCountDownTimer){
            return;
        }
        clickPlus.value = true;
        clickedBoardKey.value = e.target.alt.indexOf('meron') > -1 ? 'meron' : 'wala';
        selectChip[clickedBoardKey.value]['chip']['value'] = Math.abs(selectChip[clickedBoardKey.value]['chip']['value']);
        await bet(selectChip[clickedBoardKey.value], clickedBoardKey.value);

        setTimeout(() => {
            clickedBoardKey.value = undefined;
            clickPlus.value = false;
        }, 250);
    };

    onBeforeMount(async () => {
        await getSabongEvents();
        generateSabongInfo(gameType);
        exchangeInfo.value = await setCurrencyInfo();
        // 배당율 정보가져오기
        commision.value = await setCommisionInfo(gameIdx);
        setCurrencyChip(route.params.gameType, 'Pro');
        if (sabongInfo["rooms"]?.["event_id"]) {
            prevEventId.value = sabongInfo["rooms"]?.["event_id"];
        }
        await getGameResult(gameType, prevEventId.value);
        //0326 await generateSabongScoreBoard(sabongInfo["history"], gameType);
        if (Object.keys(sabongInfo["rooms"]).length > 0 && getVinusSabongRoomStatus(sabongInfo["rooms"])["tx"] != "close") {
            await getBetUsers();
        }
        topics[`sabong_result_${gameIdx}`] = undefined;
        MQTT();
        getPing();
    });

    onMounted(async () => {

        window.addEventListener("resize", windowSetScale);
        window.dispatchEvent(new Event("resize"));
        await getSabongEvents();
        generateSabongInfo(gameType);
        timer["event"]   = setInterval(async () => {
            await getSabongEvents();
            generateSabongInfo(gameType);
        }, 1000);
        timer["balance"] = setInterval(async () => {
            await getBalance(true);
        }, 3000);
        if (Object.keys(sabongInfo["rooms"]).length > 0) {
            await getBetUsers();
        }
        timer["betUsers"] = setInterval(async () => {
            if (Object.keys(sabongInfo["rooms"]).length > 0 && getVinusSabongRoomStatus(sabongInfo["rooms"])["tx"] != "close") {
                await getBetUsers();
            }
        }, 5000);
    });

    onUnmounted(() => {
        window.removeEventListener("resize", windowSetScale);
        Object.keys(timer).forEach(key => {
            clearInterval(timer[key]);
        });
    });

    const isttz = "Asia/Kolkata";
    momentTimer.value = setInterval(() => {
        currentTime["date"] = moment(moment.tz(moment(), isttz)).format("YYYY-MM-DD");
        currentTime["time"] = moment(moment.tz(moment(), isttz)).format("HH:mm:ss");
    }, 1000);

    const windowSetScale = (event) => {
        let scaleSize         = 1;
        let clientAspectRatio = 1920 / 1080;
        let innerWidth        = event.target.innerWidth;
        let innerHeight       = event.target.innerHeight;
        if (innerHeight * clientAspectRatio <= innerWidth) {
            scaleSize = innerHeight / 1080;
        } else {
            scaleSize = innerWidth / 1920;
        }
        if (rootWrap.value) {
            if(scaleSize === 1) {
                sidePaddingLeft.value.style.paddingLeft = '20px';
                sidePaddingRight.value.style.paddingRight = '20px';
            }else {
                sidePaddingLeft.value.style.paddingLeft = '0';
                sidePaddingRight.value.style.paddingRight = '0';
            }
            rootWrap.value.style.transform = `scale(${scaleSize})`;
        }
    };
    const betDetailIcon1 = reactive([
        {
            "label" : setBetLocale.roadLogo.meron,
            "bgcolor": setBetLocale.bgcolor.meron,
            "tx1" : computed(() => {
                return sabongRoadMap[gameType]["count"]["meron"];
            }),
        },
        {
            "label" : setBetLocale.roadLogo.wala,
            "bgcolor": setBetLocale.bgcolor.wala,
            "tx1" : computed(() => {
                return sabongRoadMap[gameType]["count"]["wala"];
            }),
        },
        {
            "label" : setBetLocale.roadLogo.draw,
            "tx1" : computed(() => {
                return sabongRoadMap[gameType]["count"]["draw"];
            }),
        },
        {
            "label" : setBetLocale.roadLogo.cancel,
            "tx1" : computed(() => {
                return sabongRoadMap[gameType]["count"]["cancel"];
            }),
        },
    ]);

    const betDetailIcon2 = reactive([
        {
            "src" : require("@/assets/sabong/rin-m.png"),
            "tx1" : setBetLocale.text.meron,
        },
        {
            "src" : require("@/assets/sabong/ring-w.png"),
            "tx1" : setBetLocale.text.wala,
        },
        {
            "src" : require("@/assets/sabong/ring-d.png"),
            "tx1" : setBetLocale.text.draw,
        },
        {
            "src" : require("@/assets/sabong/ring-c.png"),
            "tx1" : setBetLocale.text.cancel,
        },
    ]);

    const betDetailIcon3 = reactive([
        {
            "src" : require("@/assets/sabong/l-text.png"),
            "tx1" : "Llamado",
        },
        {
            "src" : require("@/assets/sabong/d-text.png"),
            "tx1" : "Dehado",
        },
        {
            "src" : require("@/assets/sabong/p-text.png"),
            "tx1" : "Parehasan",
        },
        {
            "src" : require("@/assets/sabong/c-text.png"),
            "tx1" : "Cancel",
        },
    ]);

    const clickIcon = (key) => {
        if (key === "exit") {
            router.push("/lobby");
        }
        if (key === "full") {
            if (document.fullscreenElement) {
                document.exitFullscreen();
            } else {
                document.documentElement.requestFullscreen();
            }
        }
    };

    const toggleSlideMenu = {
        open:'0px',
        close:'-450px'
    }
    const isShow = ref(true);
    const slideMenu = () => {
        isShow.value = !isShow.value
        let menu = document.querySelector('.inGameMenu');
        menu.style.marginRight = isShow.value ? toggleSlideMenu.open : toggleSlideMenu.close;
    };

</script>
<style scoped>
.currency {
	color: white;
}
span.betCountdown {
	color: gold;
	font-size: 20px;
}

.cancelResultWrap {
	display: flex;
	flex-direction: column !important;
	justify-content: center;
	align-items: center;
	width: 100% !important;
	font-size: 24px;
}
.cancelResultWrap .resultBox {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 120px;
	height: 30px;
	padding: 7px;
}

.resultBox.wala {
	background-color: #2056af;
	text-transform: uppercase;
}
.resultBox.meron {
	background-color: #b13636;
	text-transform: uppercase;
}
.resultBox.draw {
	background-color: #26813b;
	text-transform: uppercase;
}

.timeBankWrap{
	position: absolute !important;
	right: 15px;
	bottom: 15px;
	width: 120px;
	height: 120px;
}

.inGameWrap{
	/*width: 100%;*/
	/*height: 100vh;*/
    width: 1920px;
    height: 1080px;
	padding: 0 6px;
	transform-origin: top;
}
.inGameHeader{
    width: 100%;
    height: 66px;
    display: flex;
    justify-content: center;
    align-items: center;
    color:#fff;
    position: relative;
}
.inGameHeader .headerMid{
    height: 70px;
    display: flex;
    align-items: center;
    gap: 17px;
}

.inGameHeader .headerMid span{
    display: flex;
    align-items: center;
    height: 68px;
    font-size: 28px;
}
.inGameHeader .headerMid span img{
    width: 100px;
	position: relative;
	top: 6px;
}

/*
.inGameHeader .headerMid span{
    font-size: 28px;
}
*/
.inGameHeader .headerSide{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 68px;
    position: absolute;
}
.inGameHeader .headerSide.sidePadding{
    padding-left: 20px;
 }
.inGameHeader .headerLeft{
    display: flex;
    flex-direction: column;
    gap: 3px;
}
.inGameHeader .headerRight{
    width: 580px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 5px;
    font-size: 18px;
}.inGameHeader .headerRight.sidePadding{
     padding-right: 20px;
 }
.inGameHeader .headerRight .mrnBetWrap,
.inGameHeader .headerRight .walaBetWrap{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
/* 리스트 옆 배당률 표시 추가 (재형) */
.odds {
    font-size: 18px;
    color: #19e657;
}
.inGameHeader .headerRight .mrnBetWrap .betInfo,
.inGameHeader .headerRight .walaBetWrap .betInfo{
    display: flex;
    justify-content: space-between;
    gap: 12px;
}
.inGameHeader .headerRight .mrnBetWrap .betInfo .inr,
.inGameHeader .headerRight .walaBetWrap .betInfo .inr{
    display: flex;
    align-items: center;
    gap: 13px;
}
.inGameHeader .headerRight .mrnBetWrap .num,
.inGameHeader .headerRight .walaBetWrap .num{
    color: #ffce23;
    font-size: 14px;
}
.inGameHeader .headerRight .mrnBetWrap .name{
    /* color: #e82121; */
	font-weight: bold;
}
.inGameHeader .headerRight .walaBetWrap .name{
    /* color: #1a70ff; */
	font-weight: bold;
}
/*.inGameHeader .headerRight .progressOuter{
    width: 260px;
    height: 24px;
    background: #5a5a5c;
    border-radius: 2rem;
    position: relative;
    overflow: hidden;
}
.inGameHeader .headerRight .progressIn{
    width: 50%;
    height: 100%;
}*/
/*.inGameHeader .headerRight .mrnBetWrap .progressIn{
    background: #b13636;
}
.inGameHeader .headerRight .walaBetWrap .progressIn{
    background: #2056af;
}*/
/*.inGameHeader .headerRight .progressOuter span{
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
}*/
.inGameBody{
    width: 100%;
    display: flex;
    justify-content: top;
    flex-wrap: nowrap;
}
.inGameTopBox{
    width: 100%;
    height: 756px;
    display: flex;
    justify-content: left;
    flex-wrap: nowrap;
}

.inGameTopMidBox{
	width: 1318px;
}
.inGameBarWrap{
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	gap: 3px;
}

.inGameProgressBarTop{
	position: relative;
    border-radius: 2rem;
	width: 260px;
	height: 100%;
	background-color: #5a5a5c;
	overflow-x: hidden;
}
.aniProgressBarWala{
	position: absolute;
	right: 0;
	top: 0;
	height: 100%;
	background-color: #bb3a3a;
	border-radius: 0;
}
.aniProgressBarWala p{
	position: absolute;
	top: 50%;
	right: 40px;
	transform: translateY(-50%);
	color: #ffce23;
	font-size: 24px;
}

.inGameProgressBarBot{
	position: relative;
    border-radius: 2rem;
    width: 260px;
	height: 100%;
	background-color: #5a5a5c;
	overflow-x: hidden;
}
.aniProgressBarMrn{
	position: absolute;
	right: 0;
	top: 0;
	height: 100%;
	background-color: #2056af;
}
.aniProgressBarMrn p{
	position: absolute;
	top: 50%;
	left: 40px;
	transform: translateY(-50%);
	color: #ffce23;
	font-size: 24px;
}

.ProgressBarHp{
	position: relative;
	display: flex;
    justify-content: center;
    align-items: center;
	width: 100%;
	height: 24px;
	gap: 115px;
    border-radius: 2rem;
}


.ProgressBarHp > span{
	font-weight: bold;
	font-size: 18px;
	color: #fff;
}

.videoWrap{
	position: relative;
	width: 100%;
	height: 755px;
	text-align: center;
	color: #fff;
	background-color: #1b1b1b;
	overflow: hidden;
}
.videoWrap > p{
	position: absolute;
	line-height: 1.2em;
	top: 6px;
	left: 50%;
	transform: translateX(-50%);
	z-index: 1;
	font-size: 16px;
	-webkit-text-stroke: 1px #000;
}
.videoWrap > img{
	max-width: 100%;
	height: auto;
	vertical-align: middle;
}
.videoWrap video {
	width: 100%;
	height: auto;
}
.videoWrap .btmWrap{
	position: absolute;
	bottom: 6px;
	left: 50%;
	transform: translateX(-50%);
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 6px;
}
.videoWrap .btmWrap button{
	width: 238px;
	height: 68px;
	border-radius: 8px;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 8px;
	font-size: 20px;
    animation: slide-top .4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
.videoWrap .btmWrap button.sabongCancelBtn{
	border: solid 2px #94a2b6;
	background-color: #667180;
	color: #fff;
}
.videoWrap .btmWrap button.sabongConfirmBtn{
	border: solid 2px #3f82e9;
	background-color: #2056af;
	color: #fff;
}

.inGameMenu{
	position: absolute;
	right: 0;
	top: 6px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 60px;
	background-color: rgba(0, 0, 0, 0.75);
	padding: 5px 7px 5px 22px;
	gap: 31px;
	border-radius: 14px 0 0 14px;
	z-index: 2;
    transition: all .5s ease;
}

.inGameMenu img{
	cursor: pointer;
    transition: all .3s ease;
}

InGameIcons:active{
    transform: scale(0.85);
}

.slideMenuBtn{
    transform: rotate(180deg);
}

.progressBox{
	width: 1264px;
	height: 32px;
	margin: 8px auto;
}
.progressBox .inGameProgressBar{
	position: relative;
	width: 100%;
	height: 100%;
	background-color: #5a5a5c;
	border-radius: 2rem;
	overflow: hidden;
}

/* lastCall box-shadow 효과 */
.inGameProgressBar.lastCall{
    box-shadow: 0px 0px 10px 3px #F57D7D;
    animation: blink-1 1s infinite both;
}

.progressBox .inGameProgressBar .betTime{
    width: 100%;
    height: 100%;
    padding: 0 2%;
    position: absolute;
    z-index: 1;
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.progressBox .ProgressBarHp > span{
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	font-weight: bold;
	font-size: 26px;
	color: #fff;
}
.progressBox .aniProgressBarBet{
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
}
.aniProgressBarBet.open {
	background-color: #00936f;
}

.aniProgressBarBet.lastCall {
	background-color: rgba(88,11,11,.8);
}

.progressBox p{
	position: absolute;
	z-index: 1;
	color: #fff;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	font-size: 24px;
}

.userBetList{
	position: relative;
	width: auto;
	height: 1002px;
    margin-left: 6px;
}
.userBetListWrap{
    display: flex;
    flex-direction: column;
	height: 100%;
	width: 584px;
    padding: 6px 10px;
	background-color: #2b2b2c;
	border: 1px solid #484848;
}
.listTopTxt{
    width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	color: #fff;
	font-size: 18px;
    padding-bottom: 8px;
    border-bottom: 2px solid #484848;;
}
.userBetListWrap .listTopTxt > div{
	display: flex;
	justify-content: flex-end;
	align-items: center;
	gap: 5px;
}
.userBetListWrap.listTopTxt img{
	margin-left: 3px;
	display: flex;
	align-items: center;
}
.userBetListWrap .listTopTxt > h2{
	font-size: 20px;
}
.userBetListInner{
	display: flex;
	flex-direction: column;
    width: 100%;
    color: #fff;
    text-align: center;
}
.userBetListInner .innerTit{
	padding: 9px 0;
}
.userBetListInner ul{
	width: 100%;
	display: flex;
    justify-content: space-between;
	row-gap: 5px;
}
.userBetListInner .innerListWrap{
    height: inherit;
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow-y: auto;
}
.userBetListInner .list{
    box-sizing: border-box;
    height: 40px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
    padding: 4px 9px;
	font-size: 14px;
    background: #5a5a5c;
    border-radius: 8px;
}

.win {
	background: #26813b;
	animation: aniWinner 2s infinite;
}

@keyframes aniWinner {
	100%{
        background: #5a5a5c;
        border: 1px solid #5a5a5c;;
	}
	50%{
	}
	0%{
        border: 1px solid #6eff81;
        background: #26813b;
	}
}

.userBetListInner .user{
    display: flex;
    gap: 9px;
    justify-content: left;
    align-items: center;
	width: 25%;
}
.userBetListInner .user .flegImg{
	display: flex;
	align-items: center;
    height: 32px;
}
.userBetListInner .user .flegImg img{
    width: 32px;
}
.userBetListInner .bet {
	width: 30%;
	display: flex;
	justify-content: center;
	gap: 10px;
}
.userBetListInner .result{
	width: 20%;
    display: flex;
    justify-content: center;
    gap: 10px;
}
.userBetListInner .bet .yellow, .userBetListInner .result .yellow{
	color: #ffce23;
}
.userBetListInner .betRooster{
    display: flex;
    justify-content: center;
	width: 30%;
}

.userBetListInner .betRooster span{
    width: 104px;
    padding: 5px 0;
    border-radius: 6px;
    /* background: #b13636; */
    /* background: #2056af; */
}
.inGameBtmBox{
	margin-top: 6px;
	width: 100%;
	height: 234px;
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: left;
	gap: 6px;
    flex-wrap: nowrap;
}
.inGameBtmLeftBox{
	width: 584px;
	height: 100%;
}

.userPlayInfo{
	width: 100%;
	height: 46px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	gap: 5px;
	margin-bottom: 6px;
}
.userPlayInfo > div{
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	color: #fff;
	font-size: 18px;
	background-color: #2b2b2c;
	border: 1px solid #484848;
	padding: 0 8px;
    gap: 10px;
}
.userPlayInfo > div ul{
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 8px;
	color: #ffce23;
}

.scoreboardBox{
	width: 584px;
    height: 188px;
	background-color: #2b2b2c;
	border: 1px solid #484848;
	padding: 8px 5px 5px;
}
.scoreboardInner{
	width: 100%;
	/*background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAAXNSR0IArs4c6QAAABxJREFUKFNjZCAO/GckTh3DqEK8AQUOnv/EhCUAZm8KAZQzbbIAAAAASUVORK5CYII=") repeat #fff;
	background-size: 23px 23px;*/
}
.betDetailBox{
	width: 100%;
	height: 22px;
	display: flex;
	flex-direction: row;
	align-items: center;
	padding-left: 5px;
	margin-bottom: 9px;
}
.betDetailBox li{
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 10px;
	line-height: 22px;
	color: #fff;
	margin-right: 18px;
}
.label {
	width: 20px;
	height: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 6px;
}
.label.R {
	background-color: red;
}
.label.B {
	background-color: blue;
}
.label.D {
	background-color: green;
}
.label.C {
	background-color: gray;
}
.sbb1 .scoreboardInner{
	height: 146px;
}
.sbb1 > .betDetailBox > li > img{
	width: 23px;
	vertical-align: middle;
	margin-right: 5px;
}

.inGameBtmMidBox{
    display: flex;
    flex-direction: column;
    gap: 7px;
	width: 728px;
	position: relative;
}
.battingBlock {
	position: absolute;
	display: flex;
	flex-direction: column;
	gap: 7px;
	width: 730px;
	justify-content: center;
	align-items: center;
	font-size: 20px;
	height: 200px;
	bottom: 0;
	left: -1px;
	z-index: 2;
	background-color: rgba(0, 0, 0, 0.8);
	color: white;
	cursor: not-allowed;
}
.messageBox{
    width: 100%;
    height: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(144, 153, 183);
}
.messageBox.open {
	background-color: #12ab7c;
}

.messageBox.lastCall {
	background-color: rgba(88,11,11,.8);
}


.messageBox p{
    color: #fff;
    font-size: 24px;
}
.betBoardBox{
    width: 100%;
    height: 142px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.betBoardBox .betBoard{
    padding: 6px 10px;
    position: relative;
    height: 100%;
}

.betBoardBox .betBoard.meronBoard {
    width: 360px;
    border: solid 1px #ff4343;
    background-image: linear-gradient(to top, #981e1e, #be4242);
    outline: solid 4px transparent;
    transition: outline 0.25s;
}
.betBoard{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}
/* .betPlMaBox.on {
    outline: solid 4px #ff4343;
    animation: click 0.20s;
} */

.betBoardBox .betBoard.walaBoard {
    width: 360px;
    border: solid 1px #1d7dc7;
    background-image: linear-gradient(to top, #0d3880, #2056af);
    outline: solid 4px transparent;
    transition: outline 0.25s;
}
/* span.on {
    outline: solid 4px #1d7dc7;
    animation: click 0.2s;
} */

/* .betBoard.meronBoard:active,
.betBoard.walaBoard:active{
     transform: scale(0.99);
 } */



.betBoardBox .betBoard p{
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: #fff;
	font-size: 28px;
	font-weight: bold;
}
.betBoardBox .betBoard p span{
	font-weight: 300;
	font-size: 42px;
    color: #19e657;
}
.betBoardBox .betBoard .betBoardDetailBox{
	position: absolute;
	bottom: 4px;
	left: 50%;
	transform: translateX(-50%);
	width: 90%;
	height: 26px;
	border-radius: 2rem;
	font-size: 18px;
	color: #ffce23;
	text-align: center;
	line-height: 26px;
}
.betBoardBox .betBoard.meronBoard .betBoardDetailBox{
	background-color: #371b1b;
}
.betBoardBox .betBoard.walaBoard .betBoardDetailBox{
	background-color: #1a1a35;
}
.betBoardBotWrap{
    display: flex;
    justify-content: flex-end;
}
.betBoardBotWrap .autoBet{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 16px;
}
.autoBet.toggle-switch input[type=checkbox] {
    display: none;
}
.autoBet.toggle-switch label {
    cursor: pointer;
}
.autoBet.toggle-switch span {
    font-size: 20px;
    color: #fff;
}
.autoBet.toggle-switch label .toggle-track {
    display:block;
    height:20px;
    width:40px;
    border-radius:20px;
    position:relative;
    transition: .1s ease-in;
}
/* 22.02.28 박재형 추가 -,+버튼 클릭적용 */
.meronBoard .autoBet.toggle-switch label .toggle-track,
.meronBoard .betPlMaBox{
    background: #371b1b;
}
.meronBoard .betPlMaBox>span>img{
    transition: .1s;
}
.meronBoard .betPlMaBox>span>img.on{
    animation: click 0.30s;
}

.walaBoard .autoBet.toggle-switch label .toggle-track,
.walaBoard .betPlMaBox{
    background: #1a1a35;
}
.walaBoard .betPlMaBox>span>img{
    transition: .1s;
}
.walaBoard .betPlMaBox>span>img.on{
    animation: click 0.30s;
}
@keyframes click {
    0% {
        transform: scale(0.8);
    }
    50% {
        transform: scale(1.3);
    }
    100% {
        transform: scale(1);
    }
}
.autoBet.toggle-switch input[type="checkbox"]:checked + label .toggle-track {
    background: #19e657;
}

.autoBet.toggle-switch .toggle-track:before{
    content:'';
    display:inline-block;
    height: 20px;
    width: 20px;
    background: #fff;
    border-radius: 2rem;
    position:absolute;
    top:0;
    left:0;
    /* transition:right .2s ease-in; */
    transition: .2s ease-in;
}

.autoBet.toggle-switch input[type="checkbox"]:checked + label .toggle-track:before{
    /* left:20px; */
    transform: translateX(20px);
}
.betAddBox{
    width: 100%;
    height: 38px;
    display: flex;
    flex-direction: row;
    gap: 8px;
}
.betAddBox .betAdd{
    width: 100%;
    height: 38px;
    display: flex;
    flex-direction: row;
    gap: 5px;
}
.betAddBox .betAdd span{
    width: 100%;
    height: 38px;
    border-radius: 4px;
    text-align: center;
    color: #fff;
    line-height: 38px;
    cursor: pointer;
}
.betAddBox .meronAdd span{
    background-image: linear-gradient(to bottom, #bd4242, #981e1e);
    border: 1px solid #ff4343;
}
.betAddBox .walaAdd span{
    background-image: linear-gradient(to bottom, #2056ae, #0e3981);
    border: 1px solid #1d7dc7;
}
.betPlusMainus{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
}
.betPlusMainus>span{
    font-size: 20px;
    color: #fff;
}
.betPlusMainus .betPlMaBox{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 70%;
    color: #fff;
    height: 36px;
    /* padding: 0 6px; */
}
.betPlusMainus .betPlMaBox span{
    display: flex;
    align-items: center;
    height: 100%;
}
.betPlusMainus .betPlMaBox span img{
    padding: 10px 6px;
    cursor: pointer;
}
.betPlusMainus .betPlMaBox input{
    background: none;
    font-size: 20px;
    width: 50%;
    color: #fff;
    text-align: center;
}

.chipBox > img{
	width: 72px;
	position: absolute;
	bottom: 0;
	left: 0;
}

/* active 220302 이은정 추가*/
span.active{
    opacity: 1;
    animation: chipClick 0.2s;
    clip: rect(0px, 41px, 100px, 0px);
    border: 2px solid #29FF5B !important;
}

@keyframes chipClick {
    0%{
        transform: scale(0.9);
    }
    50%{
        transform: scale(1.05);
    }
    100%{
        transform: scale(1);
    }
}

.inGameBtmRightBox{
	width: 584px;
	height: 100%;
	display: flex;
	flex-direction: column;
	gap: 4px;
}
.sbb2,
.sbb3{
	width: 584px;
	height: 120px;
	padding: 4px 6px;
}
.inGameBtmRightBox .betDetailBox{
	gap: 13px;
	margin-bottom: 3px;
	height: 12px;
}
.inGameBtmRightBox .betDetailBox > li{
	font-size: 11px;
	line-height: 11px;
}
.inGameBtmRightBox .scoreboardInner{
	height: 93px;
	background-size: 15px;
}
.sbb2 > .betDetailBox > li > img{
	width: 13px;
	vertical-align: middle;
	margin-right: 5px;
}
.sbb3 > .betDetailBox > li > img{
	height: 20px;
	vertical-align: middle;
	margin-right: 5px;
}
.matchRsltWrap{
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	width: 100%;
	height: 200px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-direction: column;
}
.matchRsltWrap h1{
	font-size: 40px;
	line-height: 85px;
}
.resultPriceBox h2{
	font-size: 28px;
	color: #ffe539;
}
.resultPriceBox{
	width: 780px;
	height: 44px;
	line-height: 41px;
	text-align: center;
}
.matchRsltWrap.meron{
	background-color: rgba(88,11,11,.8);
}
.matchRsltWrap.wala{
	background-color: rgba(16,56,109,.8);
}
.matchRsltWrap.draw{
	background-color: rgba(18,100,29,.8);
}
.matchRsltWrap.cancel{
	background-color: rgba(32, 32, 32, 0.8);
}

.betPerson{
	color: #ffce23 !important;
}
.disabled {
	opacity: 0.5;
	cursor: not-allowed;
}

@keyframes slide-top {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-12px);
    }
}
@keyframes blink-1 {
    0%{
        box-shadow: none;
    }
    50%{
        box-shadow: 0px 0px 10px 3px #F57D7D;
    }
    100%{
        box-shadow: none;
    }
}

.bgChipsWrap{
    position: absolute;
    height: 80px;
    width: initial;
    flex-direction: row;
    align-items: center;
    gap: 18px;
    display: flex;
    justify-content: center;
}

</style>
